import React from "react"

import { graphql } from "gatsby"

import { StateProvider } from "state/state"
import { reducer, initialState } from "state/reducer"
import { HCP_MENU } from "constants/ContentfulContentTypes"

import Layout from "components/Layout"
import SEO from "components/Seo"
import PageTitle from "components/PageTitle/PageTitle"
import SiteMapMenu from "components/SiteMapMenu/SiteMapMenu"

import "../styles/main.scss"
import styles from "../styles/pages/site-map.module.scss"

const SiteMap = ({ data }) => {
  const siteMapMenu = data.page?.content?.find(
    (item) => item.__typename === HCP_MENU
  )

  return (
    <StateProvider initialState={initialState} reducer={reducer}>
      <Layout>
        <SEO title={data.page.title} description={data.page.description} />
        <main className={styles.content}>
          <PageTitle title={"Site Map"} />
          <SiteMapMenu menuItems={siteMapMenu.menuElements} />
        </main>
      </Layout>
    </StateProvider>
  )
}

export default SiteMap

export const query = graphql`
  query {
    page: contentfulHcpPage(label: { eq: "Site Map" }) {
      label
      url
      title
      description
      content {
        ...MenuFragment
      }
    }
  }
`
