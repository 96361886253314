import React from "react"
import { Container } from "react-bootstrap"

import {
  NAVIGATION_ELEMENT,
  MENU_ELEMENT,
} from "constants/ContentfulContentTypes"

import styles from "./SiteMapMenu.module.scss"

const SiteMapMenu = ({ menuItems }) => {
  const renderNavigationElement = ({ label, action }) => {
    return (
      <li className={styles.menuItem} key={label}>
        <a className={styles.anchor} href={action.url}>
          {label}
        </a>
      </li>
    )
  }

  const renderMenuElement = ({ label, menuElements }) => {
    return (
      <li className={styles.menuItem} key={label}>
        <h2 className={styles.submenuLabel}>{`${label}:`}</h2>
        <ul className={styles.submenu}>
          {menuElements.map((item) => renderNavigationElement(item))}
        </ul>
      </li>
    )
  }

  return (
    <Container fluid as={"section"} className={styles.sitemapMenu}>
      <Container>
        <ul className={styles.menu}>
          {menuItems.map((item) => {
            let renderedMenuItem

            switch (item.__typename) {
              case NAVIGATION_ELEMENT:
                renderedMenuItem = renderNavigationElement(item)
                break
              case MENU_ELEMENT:
                renderedMenuItem = renderMenuElement(item)
                break
              default:
                break
            }

            return renderedMenuItem
          })}
        </ul>
      </Container>
    </Container>
  )
}

export default SiteMapMenu
